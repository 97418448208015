@font-face {
  font-family: "diatype_pre_trialregular";
  src: url("diatypepretrial-regular-webfont.woff2") format("woff2"),
    url("diatypepretrial-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

/* RESET */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html,
body {
  font-family: "diatype_pre_trialregular";
  height: 100%;
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: 0.5px;
  scroll-behavior: smooth;
  text-rendering: geometricPrecision;
  -moz-font-feature-settings: "kern" 1;
  -ms-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-feature-settings: "ss12" on;
}

/* TYPOGRAPHY */

p {
  margin-bottom: 0;
  margin-top: 0;
}

.text-small {
  font-size: 23px;
  line-height: 1.3;
  letter-spacing: 0.35px;
}

.text-large {
  font-size: 7vw;
  line-height: 1.08;
  letter-spacing: 0.8px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

a:hover {
  opacity: 0.5;
}

/* SLIDER */

.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.link-list {
  width: 65vw;
  max-width: 600px;
  text-align: left;
}

.link-list li {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.link-list-wrap {
  margin-top: 1.5em;
}

.text-block {
  width: 65vw;
  max-width: 600px;
}

.video-player,
.audio-player {
  display: none;
}

/* Release Slides */

.title-large {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100vh;
  width: 65vw;
}

.cover-image {
  width: 80vw;
  height: 80vh;
  display: flex;
  justify-content: center;
}

.cover-image img {
  width: 100%;
  object-fit: contain;
}

/* Carousel Status */

.carousel .carousel-status {
  font-size: inherit;
  line-height: 1em;
  letter-spacing: 0.35px;
  position: fixed;
  top: 1rem;
  left: 1rem;
  text-shadow: none;
  color: black;
}

.control-next:before {
  /*content: url($Iconpath+"next-arrow.svg") !important;*/
}

[dir="rtl"] .control-next:before {
  /*content: url($Iconpath+"next-arrow.svg") !important;*/
}

/* Loading Release */

.loading-release {
  font-size: 23px;
  line-height: 32px;
  letter-spacing: 0.35px;
  position: fixed;
  top: 20px;
  left: 20px;
  text-shadow: none;
  color: black;
}

@media screen and (max-width: 650px) {
  html,
  body {
    font-size: 16px;
    line-height: 1.5;
  }

  .text-small {
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.5px;
  }

  .carousel .carousel-status {
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 1;
    top: 0.5rem;
    left: 0.5rem;
  }

  .text-large {
    font-size: 38px;
    line-height: 1.08;
    letter-spacing: 0.8px;
  }

  .title-large {
    width: 80vw;
  }

  .link-list {
    width: 88vw;
    padding: 1.5rem;
    text-align: left;
  }

  .text-block {
    width: 88vw;
    padding: 1.5rem;
  }

  .carousel,
  .wrap {
    height: calc(var(--vh, 1vh) * 73);
  }

  .carousel.carousel-slider {
    position: fixed;
    top: 0;
  }
}
